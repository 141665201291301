import React from 'react';
import mcli from '../../libs/matrix';
import { UserEvent } from 'matrix-js-sdk';
const ChatPresence = ({ userId, presence: initPresence, isSelected, }) => {
    const [presence, setPresence] = React.useState(null);
    React.useEffect(() => {
        if (mcli === null)
            return;
        const updatePresence = (mEvent) => {
            if (!mEvent)
                return;
            const content = mEvent.getContent();
            const sender = mEvent.getSender();
            if (sender === userId)
                setPresence(content.presence);
        };
        mcli.on(UserEvent.Presence, updatePresence);
        return () => {
            mcli.removeListener(UserEvent.Presence, updatePresence);
        };
    });
    /* presence has always the old value in chatHeader, but not realy.
        this seems the only way to proof that presence is null. You cant set initPresence as default
        its not working. But why? Why? cry alot. q.q
    */
    const choose = presence === null ? initPresence : presence;
    return (React.createElement("div", { className: 'chat-list-icon-container' },
        React.createElement("div", { className: `${choose === 'online'
                ? 'chat-list-icon__online'
                : 'chat-list-icon__offline'} ${isSelected && 'chat-list-icon__active'} ` })));
};
export default ChatPresence;
