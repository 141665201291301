import * as React from 'react';
import { getRoomAsync } from '../../reducer/matrix';
import { sendMedia, sendMessage } from '../../libs/matrix/message';
import { useAppDispatch } from '../../hooks';
import ChatClipIcon from './Icons/ChatClipIcon';
import ChatGifIcon from './Icons/ChatGifIcon';
import ChatSendingIcon from './Icons/ChatSendingIcon';
import ChatSmileIcon from './Icons/ChatSmileIcon';
import GifPicker from '../GifPicker';
import Picker from '@emoji-mart/react';
import data from '@emoji-mart/data';
import { handleFile } from '../../libs/matrix/image';
import { init } from 'emoji-mart';
import { makeReplyMixIn } from '../../libs/matrix/reply';
import mcli from '../../libs/matrix';
import moment from 'moment';
import { unescapeHtml } from '../../libs/unescapeHTML/index';
let sendTypingDate = new Date();
let lastRoomId = '';
const ChatInput = ({ roomId, messageToReply, closeReply, }) => {
    const isMobile = window.innerWidth < 621;
    const inputRef = React.useRef(null);
    const fileUploadRef = React.useRef(null);
    const specialContainerRef = React.useRef(null);
    const isThisRef = React.useRef(null);
    const [text, setText] = React.useState('');
    const [sending, setSending] = React.useState(false);
    const [isThis, _setIsThis] = React.useState(null);
    const dispatch = useAppDispatch();
    const setIsThis = (data) => {
        isThisRef.current = data;
        _setIsThis(data);
    };
    init({ data });
    React.useEffect(() => {
        let switched = roomId !== lastRoomId;
        if (switched) {
            lastRoomId = roomId;
            if (inputRef.current) {
                inputRef.current.innerText = '';
                inputRef.current.innerHTML = '';
                inputRef.current.textContent = '';
            }
            setText('');
        }
        const hideSpecial = (evt) => {
            const selected = document.querySelector('[data-type=special]');
            if (!selected)
                return evt;
            if (!selected?.contains(evt.target)) {
                specialContainerTransEnd();
                setIsThis(null);
            }
        };
        if (isThis && isThis !== null) {
            window.addEventListener('mousedown', hideSpecial);
        }
        if (!isMobile && inputRef.current) {
            inputRef.current.focus();
        }
        if (switched) {
            specialContainerTransEnd();
            setIsThis(null);
        }
        document.addEventListener('keyup', (e) => closeSpecial(e));
        return () => {
            window.removeEventListener('mousedown', hideSpecial);
            document.removeEventListener('keyup', (e) => closeSpecial(e));
        };
    }, [sending, roomId, isThis]);
    const closeSpecial = (evt) => {
        evt.preventDefault();
        if (evt.keyCode == 27 && isThisRef.current)
            toggleSpecial(isThisRef.current);
    };
    const specialContainerTransStart = () => {
        if (!specialContainerRef.current)
            return;
        specialContainerRef.current.classList.remove('hidden', 'opacity-0');
    };
    const specialContainerTransEnd = () => {
        if (!specialContainerRef.current)
            return;
        specialContainerRef.current.classList.add('hidden', 'opacity-0');
    };
    const send = async (e) => {
        if (!inputRef || !inputRef.current)
            return;
        const msg = inputRef.current?.innerText;
        if (!msg || msg?.length === 0)
            return;
        if (inputRef.current) {
            inputRef.current.innerText = '';
            inputRef.current.innerHTML = '';
            inputRef.current.textContent = '';
        }
        setSending(true);
        if (messageToReply) {
            const content = {
                body: cleanMessage(msg),
                msgtype: 'm.text',
                'm.relates_to': {},
                formatted_body: '',
            };
            content['m.relates_to'] = {
                ...(content['m.relates_to'] || {}),
                // @ts-ignore
                ...makeReplyMixIn(messageToReply.replyEvent.event_id),
            };
            content.body = messageToReply.replyEvent.body + content.body;
            (content.format = 'org.matrix.custom.html'),
                (content.formatted_body =
                    messageToReply.replyEvent.html + text);
            sendMessage(roomId, content, 'm.text')
                .then(() => {
                dispatch(getRoomAsync(roomId));
            })
                .catch(console.error);
        }
        else {
            sendMessage(roomId, msg, 'm.text')
                .then(() => {
                dispatch(getRoomAsync(roomId));
            })
                .catch(console.error);
        }
        setText('');
        closeReply();
    };
    const getEmoticon = (text) => {
        const dany = data;
        const found = dany.emoticons[text];
        return found;
    };
    const handleSubmit = (e) => {
        e.preventDefault();
        isThis && toggleSpecial(isThis);
        send(e);
    };
    const handleTextInput = (emojiObject) => {
        if (!inputRef ||
            !inputRef.current ||
            inputRef.current.textContent === null ||
            !emojiObject)
            return;
        const selection = document.getSelection();
        if (!selection || selection.rangeCount === 0)
            return;
        const range = selection.getRangeAt(0);
        range.deleteContents(); // removes any selected text
        const emojiNode = document.createTextNode(emojiObject.native);
        range.insertNode(emojiNode);
        range.setStartAfter(emojiNode);
        range.setEndAfter(emojiNode);
        selection.removeAllRanges();
        selection.addRange(range);
    };
    function dataURLtoFile(dataurl, filename) {
        var arr = dataurl.split(','), mime = arr[0].match(/:(.*?);/)[1], bstr = atob(arr[1]), n = bstr.length, u8arr = new Uint8Array(n);
        while (n--) {
            u8arr[n] = bstr.charCodeAt(n);
        }
        let extension = '.jpg';
        switch (mime) {
            case mime.includes('png'):
                extension = '.png';
                break;
            case mime.includes('mp4'):
                extension = '.mp4';
                break;
        }
        return new File([u8arr], filename.replaceAll(' ', '_') + extension, {
            type: mime,
        });
    }
    const handleClipboardImageBlob = (e) => {
        const clipBoardImage = e.target?.innerHTML;
        if (clipBoardImage) {
            const div = document.createElement('div');
            div.innerHTML = clipBoardImage;
            const img = div.querySelector('img');
            const imageSrc = img?.src;
            const formatDate = moment().format('DD-MM-YYYY_hh:mm:ss');
            const imgFile = dataURLtoFile(imageSrc, `Screenshot_${formatDate}`);
            if (imgFile) {
                handleFile(imgFile);
            }
        }
    };
    const handleURLFileUpload = async (url) => {
        setSending(true);
        specialContainerTransEnd();
        setIsThis(null);
        const res = await fetch(url);
        if (!res.ok) {
            setSending(false);
            console.error('Error downloading Tenor Media -> ', res.status, res.statusText);
        }
        const urlParts = url.split('/');
        const name = urlParts[urlParts.length - 1];
        const blob = await res.blob();
        const file = new File([blob], name, { type: 'image/gif' });
        return sendMedia(roomId, 'm.image', file).finally(() => {
            setSending(false);
        });
    };
    const handleFileUpload = (e) => {
        if (!e.target.files)
            return;
        const file = e.target.files[0];
        if (file) {
            handleFile(file);
        }
    };
    const toggleSpecial = (n) => {
        if (isThis && isThisRef.current === n) {
            specialContainerTransEnd();
            setIsThis(null);
        }
        else {
            specialContainerTransStart();
            setIsThis(n);
        }
    };
    const cleanMessage = (replyMessage) => {
        const regex = />(.*?)</g;
        const clean = regex.exec(replyMessage);
        if (clean)
            return clean[1];
        return unescapeHtml(replyMessage);
    };
    /* @ts-ignore WIP */
    const replaceTextToEmoji = (emoticanPattern) => {
        if (!inputRef ||
            !inputRef.current ||
            inputRef.current.textContent === null)
            return;
        const dany = data;
        const emoticon = getEmoticon(emoticanPattern);
        if (!emoticon)
            return emoticanPattern;
        const emoji = dany.emojis[emoticon];
        const text = emoji?.skins[0]?.native || emoticanPattern;
        inputRef.current.textContent = inputRef.current.textContent.replace(emoticanPattern, text);
        return text;
    };
    return (React.createElement(React.Fragment, null,
        React.createElement("form", { "data-type": 'special', onSubmit: handleSubmit },
            React.createElement("div", { className: "flex flex-col flex-nowrap" },
                React.createElement("div", { ref: specialContainerRef, className: `hidden transition ease-in-out delay-150 flex justify-end mr-6` },
                    isThis === 1 && (React.createElement(Picker, { data: data, onEmojiSelect: handleTextInput, locale: navigator.language, theme: 'light', skinTonePosition: 'none', previewPosition: 'none' })),
                    isThis === 2 && (React.createElement(GifPicker, { onClick: handleURLFileUpload }))),
                React.createElement("div", { className: "chat-input-field" },
                    React.createElement("div", { className: "chat-input_wrapper" },
                        messageToReply && (React.createElement("div", { className: "chat-message_to-reply-wrapper" },
                            React.createElement("div", { className: "chat-message_to-reply" },
                                React.createElement("div", { className: "chat-message__reply-container" },
                                    React.createElement("div", { className: "chat-message__reply-sender" },
                                        messageToReply?.user?.name,
                                        React.createElement("div", { className: "chat-message__reply-close", onClick: closeReply },
                                            React.createElement("svg", { xmlns: "http://www.w3.org/2000/svg", viewBox: "0 0 320 512", className: "w-3 h-3 mr-1 mt-1 fill-gray-400 hover:fill-gray-500 cursor-pointer" },
                                                React.createElement("path", { d: "M312.1 375c9.369 9.369 9.369 24.57 0 33.94s-24.57 9.369-33.94 0L160 289.9l-119 119c-9.369 9.369-24.57 9.369-33.94 0s-9.369-24.57 0-33.94L126.1 256L7.027 136.1c-9.369-9.369-9.369-24.57 0-33.94s24.57-9.369 33.94 0L160 222.1l119-119c9.369-9.369 24.57-9.369 33.94 0s9.369 24.57 0 33.94L193.9 256L312.1 375z" })))),
                                    cleanMessage(messageToReply?.message))))),
                        React.createElement("div", { className: `chat-input ${messageToReply
                                ? 'chat-input__with-reply'
                                : ''}` },
                            React.createElement("span", { id: "span-el", ref: inputRef, contentEditable: true, className: "textarea w-full", role: "textarea", 
                                // @ts-ignore
                                onInput: (e) => {
                                    const div = document.createElement('div');
                                    div.innerHTML = e.target.innerHTML;
                                    if (div.childElementCount === 1) {
                                        if (div.querySelector('img')) {
                                            e.preventDefault();
                                            handleClipboardImageBlob(e);
                                            e.target.textContent = '';
                                            setText('');
                                            return;
                                        }
                                    }
                                    const value = e.target.innerText ||
                                        e.target.textContent;
                                    setText(value);
                                    //e.target.textContent = e.target.innerText || e.target.textContent;
                                }, onKeyUp: (e) => {
                                    const now = new Date();
                                    if (sendTypingDate.getTime() / 1000 +
                                        2 <
                                        now.getTime() / 1000) {
                                        sendTypingDate = now;
                                        mcli.sendTyping(roomId, true, 3000);
                                    }
                                }, onKeyDown: (e) => {
                                    if (e.code === 'Enter' && !e.shiftKey) {
                                        handleSubmit(e);
                                        // @ts-ignore
                                        e.target.textContent = '';
                                        // @ts-ignore
                                        e.target.innerHTML = '';
                                        setText('');
                                    }
                                } }),
                            React.createElement("div", { className: "chat-input__icon-wrapper" },
                                React.createElement("div", { className: "chat-input__emoji-icon", onClick: () => toggleSpecial(1) },
                                    React.createElement(ChatSmileIcon, null)),
                                React.createElement("div", { className: "cursor-pointer h-6 w-6 rounded-md mt-1 mr-1" },
                                    React.createElement("input", { ref: fileUploadRef, className: "hidden", id: "data-picker", type: "file", onChange: (e) => {
                                            if (e.target.files)
                                                handleFileUpload(e);
                                        } }),
                                    React.createElement("label", { htmlFor: "data-picker", className: "chat-input__clip-icon" },
                                        React.createElement(ChatClipIcon, null))),
                                React.createElement("div", { className: "cursor-pointer" },
                                    React.createElement("div", { className: "chat-input__gif-icon", onClick: () => toggleSpecial(2) },
                                        React.createElement(ChatGifIcon, null))),
                                React.createElement("div", { className: "chat-input__send-icon", onClick: send },
                                    React.createElement(ChatSendingIcon, null))))))))));
};
export default ChatInput;
