import * as React from 'react';
import mcli from '../../libs/matrix';
import { getDecryptedImageUrl } from '../../libs/matrix/message';
import { downloadFile } from '../../libs/common/download';
const imageStore = [];
const Video = React.memo(({ content }) => {
    const isDecrypted = 'file' in content;
    const fileUrl = isDecrypted ? content.file.url : content.url;
    const thumbUrl = isDecrypted
        ? content.info.thumbnail_file?.url
        : content.info.thumbnail_url;
    const foundThumbnail = imageStore.find((i) => i.url === thumbUrl);
    const foundVideo = imageStore.find((i) => i.url === fileUrl);
    const [image, setImage] = React.useState(!isDecrypted ? mcli.mxcUrlToHttp(thumbUrl) : foundThumbnail?.objUrl);
    const [video, setVideo] = React.useState(!isDecrypted ? mcli.mxcUrlToHttp(fileUrl) : foundVideo?.objUrl);
    const [clicked, setClicked] = React.useState(false);
    const [ready, setReady] = React.useState(false);
    React.useEffect(() => {
        if (content.info?.autoplay) {
            downloadVideo();
        }
        else if (isDecrypted && content.info?.thumbnail_file) {
            getDecryptedImageUrl(content.info.thumbnail_file).then((resUrl) => {
                if (resUrl) {
                    setImage(resUrl);
                    if (thumbUrl)
                        imageStore.push({
                            url: thumbUrl,
                            objUrl: resUrl || '',
                        });
                }
            });
        }
    }, []);
    const downloadVideo = () => {
        if (!foundVideo && !video && isDecrypted) {
            getDecryptedImageUrl(content.file, "video/mp4")
                .then((resUrl) => {
                if (resUrl) {
                    setVideo(resUrl);
                    imageStore.push({
                        url: fileUrl,
                        objUrl: resUrl || '',
                    });
                }
            })
                .catch(console.error);
        }
    };
    const calcHeight = (originWidth, width, originHeight) => {
        let factor = 1;
        if (originWidth < width) {
            factor = originWidth / width;
        }
        if (originWidth > width) {
            factor = width / originWidth;
        }
        return originHeight * factor;
    };
    const rawH = content.info.thumbnail_info?.h;
    const rawW = content.info.thumbnail_info?.w;
    const maxWidth = 320;
    const maxHeight = 250;
    const width = rawW ? (rawW <= maxWidth ? rawW : maxWidth) : maxWidth;
    const height = calcHeight(rawW || maxHeight, width, rawH || 300);
    return (React.createElement(React.Fragment, null,
        React.createElement("div", { className: "h-fill w-fill" },
            image && !clicked && !video && (React.createElement(React.Fragment, null,
                !ready && (React.createElement("div", { className: `max-w-full image-skeleton`, style: { width, height } })),
                !video && (React.createElement("img", { onLoad: () => {
                        setReady(true);
                    }, src: image, onClick: () => {
                        downloadVideo();
                        setClicked(true);
                    }, className: `rounded ${!ready && 'hidden'}`, "data-type": "modal-btn" })))),
            video && (React.createElement("video", { style: { width, height }, loop: content.info?.autoplay, preload: "metadata", controls: true, poster: image || '' },
                React.createElement("source", { src: video, type: "video/mp4" }))),
            React.createElement("a", { className: "chat-message-download", onClick: () => downloadFile(image || '', content?.body || 'file.mp4') }, "download"))));
});
export default Video;
